<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Movimientos</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Detalle de Movimientos Almacen</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row mb-4"
    >
      <h2><strong>DETALLE MOVIMIENTOS DE ALMACEN</strong></h2>
    </div>
    <p><strong>TIPO DE MOVIMIENTO:</strong> {{ movimiento.tipo_movalmacen }}</p>
    <div class="grid mt-4">
      <DataTable
        ref="dtmovimientos"
        :value="detalle_mov_almacen"
        class="col-12"
      >
        <Column field="mov_almacen_id" header="CÓDIGO MOVIMIENTO" />

        <Column field="nombre_producto" header="PRODUCTO/DESCRIPCIÓN">
          {{ detalle_mov_almacen.nombre_producto }}
        </Column>
        <Column field="cantidad" header="CANTIDAD" />
        <Column field="precio_compra" header="PUCompra">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.stock.cpp ?? 0) }}
          </template>
        </Column>
        <Column field="precio_sugerido" header="PUVentas">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.stock.ultimo_precio ?? 0
              )
            }}
          </template>
        </Column>
        <Column field="subtotal" header="SubTotalCompra">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.cantidad * slotProps.data.stock.cpp
              )
            }}
          </template>
        </Column>
        <Column field="subtotal" header="SubTotalVenta">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.cantidad * slotProps.data.stock.ultimo_precio
              )
            }}
          </template>
        </Column>
        <template #footer>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span
              ><strong>TOTAL COMPRAS: </strong>
              {{ convertirNumeroGermanicFormat(totalCompras) }}</span
            >
            <span class="ml-2"
              ><strong>TOTAL VENTAS: </strong>
              {{ convertirNumeroGermanicFormat(totalVentas) }}</span
            >
          </div>
        </template>
      </DataTable>
    </div>
    <div class="grid mt-2 justify-content-end">
      <Button
        @click="goBack"
        class="p-button-primary p-button-lg"
        v-tooltip.top="'Volver a Movimientos'"
      >
        <i class="pi pi-arrow-left"></i>&nbsp;ATR&Aacute;S
      </Button>
      <Button
        label="IMPRIMIR"
        icon="pi pi-print"
        class="p-button-danger ml-2 p-button-lg"
        v-tooltip.top="'Imprimir PDF'"
        @click="tipo_impresionModal = true"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
    </div>
    <Dialog
      v-model:visible="tipo_impresionModal"
      :style="{ width: '450px' }"
      header="Tipo de Impresión"
      :modal="true"
      class="p-fluid"
    >
      <div class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="tipo_impresion"
              ><strong>SELECCIONE EL TIPO DE IMPRESI&Oacute;N: </strong></label
            >
            <Dropdown
              v-model="tipo_impresionSelect"
              :options="tipos_impresion"
              optionLabel="nombre"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-12 mt-4">
            <div class="p-inputgroup flex justify-content-end">
              <Button
                label="CANCELAR"
                icon="pi pi-times"
                class="p-button-danger mr-2 p-button-lg"
                @click="tipo_impresionModal = false"
              />
              <Button
                label="IMPRIMIR"
                icon="pi pi-print"
                class="p-button-success p-button-lg"
                @click="imprimirMovimiento"
                :loading="imprimiendo"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import TipoMovimientos from "@/service/TipoMovimientos";
export default {
  data() {
    return {
      movimiento: {},
      mov_almacen: this.$route.params.id,
      detalle_mov_almacen: [],
      imprimiendo: false,
      tipo_impresionSelect: 1, // 1=Carta, 2=Ticket
      tipo_impresionModal: false,
      tipos_impresion: [
        { id: 1, nombre: "CARTA" },
        { id: 2, nombre: "TICKET" },
      ],
      id_mov: null,
    };
  },
  tipoMovimientoService: null,
  computed: {
    totalCompras() {
      let total = 0;
      this.detalle_mov_almacen.forEach((element) => {
        total += element.cantidad * element.stock.cpp ?? 0;
      });
      return total;
    },
    totalVentas() {
      let total = 0;
      this.detalle_mov_almacen.forEach((element) => {
        total += element.cantidad * element.stock.precio_venta ?? 0;
      });
      return total;
    },
  },
  created() {
    this.tipoMovimientoService = new TipoMovimientos();
  },
  mounted() {
    this.cargarMovimientos();
  },
  methods: {
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    imprimirMovimiento() {
      let datos = {
        tipo_impresion: this.tipo_impresionSelect,
        movimiento_id: this.mov_almacen,
      };
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.tipoMovimientoService.imprimirMov(datos).then(() => {
        this.imprimiendo = false;
        this.tipo_impresionSelect = 1;
      });
    },
    cargarMovimientos() {
      this.tipoMovimientoService
        .getTipoMovimiento(this.mov_almacen)
        .then((data) => {
          this.movimiento = data.movimiento;
          this.detalle_mov_almacen = data.detalle;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>